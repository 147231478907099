import React from 'react'
import Router from './src/router'
import { useFonts } from '@expo-google-fonts/raleway'
import { Loading } from './src/components/Loading'
import { NativeBaseProvider } from 'native-base'
import { QueryClientProvider } from 'react-query'
import { theme } from './src/styles/theme'
import { queryClient } from './src/config/queries'

export default function App() {
  const [fontsLoaded] = useFonts(theme.fonts)

  if (!fontsLoaded) return <Loading />
  return (
    <QueryClientProvider client={queryClient}>
      <NativeBaseProvider>
        <Router />
      </NativeBaseProvider>
    </QueryClientProvider>
  )
}
