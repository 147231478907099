import React from 'react'
import { Image, useWindowDimensions, View } from 'react-native'
import MenuHeaderImage from '../images/menu_header.png'
import { theme } from '../styles/theme'

export const MenuHeader = () => {
  const { width, height } = useWindowDimensions()

  if (width > height) return null
  return (
    <View
      style={{
        backgroundColor: theme.colors.primary[300],
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: width * 0.3,
      }}
    >
      <Image
        source={MenuHeaderImage}
        style={{ width: width * 0.5, height: width * 0.25 }}
        resizeMode="contain"
      />
    </View>
  )
}
