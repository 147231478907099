import React from 'react'
import { Image, View, Text } from 'react-native'
import SoftLogo from '../images/soft_shop_logo.png'

export const SoftShopLogo = () => {
  return (
    <View
      style={{
        opacity: 0.3,
        flexDirection: 'row',
        paddingHorizontal: 10,
        alignSelf: 'flex-start',
      }}
    >
      <Image
        style={{
          width: 60,
          height: 10,
        }}
        source={SoftLogo}
      />
      <Text style={{ textAlign: 'left', fontSize: 10 }}>
        &copy;SoftShop-menu todos os direitos reservados - 2023 v.1.0.20
      </Text>
    </View>
  )
}
