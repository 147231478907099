import React, { useState } from 'react'
import {
  Image,
  useWindowDimensions,
  View,
  Text,
  TouchableOpacity,
} from 'react-native'
import { Icon, Popover } from 'native-base'
import { EvilIcons } from '@expo/vector-icons'
import Carousel from 'react-native-reanimated-carousel'

import { theme } from '../../styles/theme'
import { IProduct } from '../../data/interfaces/product'
import { currencyConverter } from '../../utils/currencyConverter'
import { FadeImage } from '../../components/FadeImage'

export const ProductListItem = ({ item }: { item: IProduct }) => {
  const { width, height } = useWindowDimensions()
  const [position, setPosition] = useState(0)

  const isInLandscape = width > height
  const imgSize = isInLandscape ? width * 0.3 : width * 0.4
  const isSingleImage = item?.photos?.length <= 1
  const hasItemDetails = !!item?.details?.length
  const hasAdditionalDetails = !!item?.additionalDetails?.length
  const hasItemPrices = !!item?.prices?.length
  const hideBody = !hasItemDetails && !hasAdditionalDetails
  const disabledPress =
    !hasItemPrices && !hasItemDetails && !hasAdditionalDetails

  const renderPhoto = ({ uri }: IProduct['photos'][0]) => (
    <FadeImage
      source={uri}
      style={{
        width: imgSize,
        height: imgSize,
        borderRadius: 20,
      }}
    />
  )

  const positionIndicator = () => {
    const renders: Array<React.ReactNode> = []
    const indicatorWidth = imgSize / item?.photos?.length

    item?.photos?.forEach((photo, index) => {
      const backgroundColor =
        position === index
          ? theme.colors.primary['100']
          : theme.colors.primary['300']
      renders.push(
        <View
          key={photo.id}
          style={{
            height: 10,
            borderRadius: 5,
            backgroundColor,
            width: indicatorWidth,
          }}
        />
      )
    })

    return renders
  }

  return (
    <Popover
      trigger={(props, state) => (
        <View style={{ margin: 10, borderRadius: 20 }}>
          <Carousel
            enabled={false}
            width={imgSize}
            height={imgSize}
            data={item?.photos}
            autoPlayInterval={3000}
            autoPlay={!isSingleImage}
            onSnapToItem={(i) => setPosition(i)}
            renderItem={({ item: photo }) => renderPhoto(photo)}
          />

          <View
            style={{
              width: imgSize,
              flexDirection: 'row',
            }}
          >
            {positionIndicator()}
          </View>

          <TouchableOpacity disabled={disabledPress} {...props}>
            <View
              style={{
                flexDirection: 'row',
                width: imgSize,
              }}
            >
              <Text
                style={{
                  fontSize: 18,
                  fontFamily: 'bold',
                  marginTop: 10,
                  color: theme.colors.primary['400'],
                }}
              >
                {item.name}
              </Text>

              {item?.complement && (
                <Text
                  style={{
                    fontFamily: 'thin',
                    fontSize: 18,
                    color: theme.colors.primary['400'],
                    marginTop: 10,
                  }}
                >
                  {` ${item?.complement}`}
                </Text>
              )}
            </View>

            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Text
                style={{
                  fontFamily: 'regular',
                  fontSize: 20,
                  color: theme.colors.primary['500'],
                }}
              >
                {currencyConverter(item?.price)}
              </Text>
              {!disabledPress && (
                <Icon
                  as={EvilIcons}
                  name={state.open ? 'minus' : 'plus'}
                  rounded={'full'}
                  color={theme.colors.primary['400']}
                  backgroundColor={theme.colors.primary['300']}
                />
              )}
            </View>
          </TouchableOpacity>
        </View>
      )}
    >
      <Popover.Content
        style={{ width: width * 0.8, alignSelf: 'center', margin: 20 }}
      >
        <>
          {/* <Popover.Arrow bgColor={theme.colors.primary['300']} /> */}
          <Popover.Header bgColor={theme.colors.primary['300']}>
            <Text
              style={{
                fontFamily: 'bold',
                fontSize: 16,
                color: theme.colors.primary['400'],
              }}
            >
              {`${item?.name} ${item.detailsHeader}`}
            </Text>
            {item?.prices && (
              <View style={{ flexDirection: 'row', width: '100%' }}>
                {item?.prices.map((price) => {
                  if (!price.isDefault)
                    return (
                      <View
                        key={price.id}
                        style={{
                          flex: 1,
                          borderLeftWidth: 1,
                          paddingLeft: '2%',
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: 'thin',
                            color: theme.colors.primary['400'],
                          }}
                        >
                          {price.label}
                        </Text>
                        <Text
                          style={{
                            fontFamily: 'extraLight',
                            color: theme.colors.primary['400'],
                          }}
                        >
                          {currencyConverter(price.price)}
                        </Text>
                      </View>
                    )
                })}
              </View>
            )}
          </Popover.Header>
          <Popover.Body
            display={hideBody ? 'none' : 'flex'}
            bgColor={theme.colors.primary['300']}
          >
            {hasItemDetails &&
              item?.details.map((flavor) => (
                <Text
                  key={flavor.id}
                  style={{
                    fontSize: 20,
                    fontFamily: 'extraLight',
                    color: theme.colors.primary['400'],
                  }}
                >
                  {`- ${flavor.name}`}
                </Text>
              ))}

            {hasAdditionalDetails && (
              <>
                <Text
                  style={{
                    textAlign: 'right',
                    fontFamily: 'semibold',
                  }}
                >
                  Adicionais
                </Text>
                <View>
                  {item?.additionalDetails?.map((additionalDetail) => {
                    return (
                      <View key={additionalDetail.id}>
                        <Text
                          style={{
                            textAlign: 'right',
                            fontFamily: 'extraLight',
                          }}
                        >
                          {`${additionalDetail.name} ${currencyConverter(
                            additionalDetail.price
                          )}`}
                        </Text>
                      </View>
                    )
                  })}
                </View>
              </>
            )}
          </Popover.Body>
        </>
      </Popover.Content>
    </Popover>
  )
}
