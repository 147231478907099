import React from 'react'
import {
  useWindowDimensions,
  ScrollView,
  ViewProps,
  SafeAreaView,
} from 'react-native'
import { theme } from '../styles/theme'
import { SoftShopLogo } from './SoftShopLogo'

export function Container({ ...props }: ViewProps) {
  const { width, height } = useWindowDimensions()

  return (
    <ScrollView
      {...props}
      contentContainerStyle={{
        flexGrow: 1,
        backgroundColor: theme.colors.primary[100],
      }}
      showsHorizontalScrollIndicator={false}
      style={[
        {
          flex: 1,
          width,
          height: height,
          // backgroundColor: theme.colors.primary[100],
        },
        props.style,
      ]}
    >
      <SafeAreaView style={{ flex: 1 }}>{props.children}</SafeAreaView>
      <SoftShopLogo />
    </ScrollView>
  )
}
