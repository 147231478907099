import React, { useState } from 'react'
import { Image, ImageProps } from 'react-native'
import { PresenceTransition } from 'native-base'

interface IFadeImage extends Omit<ImageProps, 'source'> {
  source: string
}

export const FadeImage = ({ source, ...props }: IFadeImage) => {
  const [isLoaded, setIsLoaded] = useState(false)

  return (
    <PresenceTransition
      style={{ flex: 1 }}
      visible={isLoaded}
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        transition: {
          duration: 300,
        },
      }}
    >
      <Image
        {...props}
        source={{ uri: source, cache: 'force-cache' }}
        style={{ flex: 1 }}
        onLoadEnd={() => {
          setIsLoaded(true)
        }}
      />
    </PresenceTransition>
  )
}
