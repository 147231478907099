import { QueryClient } from 'react-query'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60 * 24 * 7, // 7 days
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours,
      refetchOnWindowFocus: false,
    },
  },
})
