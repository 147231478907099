import React from 'react'
import { NavigationContainer } from '@react-navigation/native'
import MainRouter from './Main'

export default function Router() {
  return (
    <NavigationContainer>
      <MainRouter />
    </NavigationContainer>
  )
}
