import React from 'react'
import {
  createNativeStackNavigator,
  NativeStackScreenProps,
} from '@react-navigation/native-stack'
import Menu from '../../screens/Menu'
import { MenuHeader } from '../../components/Header'
import { IProduct } from '../../data/interfaces/product'
import {
  collection,
  query,
  QuerySnapshot,
  DocumentData,
} from 'firebase/firestore'
import { db } from '../../services/firebase'
import { instance } from '../../config/instance'
import { ParamListBase } from '@react-navigation/native'
import { Loading } from '../../components/Loading'
import { useFirestoreQuery } from '@react-query-firebase/firestore'

interface MainStackScreenProps extends ParamListBase {
  Menu: { products: IProduct[] }
}
export type MenuScreenProps = NativeStackScreenProps<
  MainStackScreenProps,
  'Menu'
>['route']

const MainStack = createNativeStackNavigator<MainStackScreenProps>()

export default function MainRouter() {
  const q = collection(db, instance.name)
  const ref = query(q)

  const { data: productsData, isLoading: loading } = useFirestoreQuery<
    DocumentData,
    QuerySnapshot<IProduct>
  >(['products'], ref, {
    subscribe: true,
  })

  const products: IProduct[] = []

  productsData?.forEach((product) =>
    product.exists() ? products.push(product.data()) : null
  )

  if (loading || !products.length) return <Loading />
  return (
    <MainStack.Navigator
      screenOptions={{
        title: instance.label,
      }}
    >
      <MainStack.Screen
        name="Menu"
        component={Menu}
        initialParams={{ products }}
        options={{
          header: () => <MenuHeader />,
        }}
      />
    </MainStack.Navigator>
  )
}
