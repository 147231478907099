import React from 'react'
import { ActivityIndicator, View } from 'react-native'

import { theme } from '../styles/theme'
import { Container } from './Container'

export const Loading = () => {
  return (
    <View
      style={{
        flex: 1,
        alignContent: 'center',
        justifyContent: 'center',
        backgroundColor: theme.colors.primary['100'],
      }}
    >
      <ActivityIndicator size={50} color={theme.colors.primary['300']} />
    </View>
  )
}
