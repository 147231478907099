/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FlatList } from 'react-native'
import { useRoute } from '@react-navigation/native'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'

import { theme } from '../../styles/theme'
import { ProductListItem } from './ListItem'
import { Loading } from '../../components/Loading'
import { MenuScreenProps } from '../../router/Main'
import { Container } from '../../components/Container'
import { categoryNames } from '../../config/categories'

const TopTabs = createMaterialTopTabNavigator()

export default function Menu() {
  const [loading, setLoading] = useState(true)
  const { products } = useRoute<MenuScreenProps>().params

  const extractCommonCategories = useCallback(() => {
    const categoryIds = products.map((product) => product.categoryId)
    return [...new Set(categoryIds)].filter((item) => item)
  }, [])

  const categories = useMemo(() => extractCommonCategories(), [])

  useEffect(() => {
    if (products.length && categories.length) setLoading(false)
  }, [products, categories])

  if (loading) return <Loading />
  return (
    <TopTabs.Navigator
      screenOptions={{
        lazy: true,
        tabBarIndicatorStyle: {
          backgroundColor: theme.colors.primary[600],
        },
        tabBarLabelStyle: {
          textTransform: 'none',
        },
      }}
      initialRouteName={categoryNames('1')}
    >
      {categories.map((categorie) => {
        const categoryProducts = products.filter(
          (product) => product.categoryId === categorie
        )
        const categoryName = categoryNames(categorie)

        return (
          <TopTabs.Screen
            key={categorie}
            name={categoryName}
            options={{
              tabBarItemStyle: {
                backgroundColor: theme.colors.primary[100],
              },
            }}
          >
            {() => (
              <Container>
                <FlatList
                  numColumns={2}
                  data={categoryProducts}
                  renderItem={({ item }) => <ProductListItem item={item} />}
                  contentContainerStyle={{
                    alignItems: 'center',
                    marginTop: 10,
                  }}
                />
              </Container>
            )}
          </TopTabs.Screen>
        )
      })}
    </TopTabs.Navigator>
  )
}
