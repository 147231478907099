import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyBmBLui53yCR_Chen-oxwzSs6Jk7T86hwU',
  authDomain: 'soft-menu.firebaseapp.com',
  projectId: 'soft-menu',
  storageBucket: 'soft-menu.appspot.com',
  messagingSenderId: '987453762632',
  appId: '1:987453762632:web:455a53b59811fc96d47c7d',
  measurementId: 'G-4JS9MH2J0J',
}

const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)
export const storage = getStorage(app)
export const analytics = getAnalytics(app)
