/* eslint-disable camelcase */
import {
  Raleway_100Thin,
  Raleway_200ExtraLight,
  Raleway_300Light,
  Raleway_400Regular,
  Raleway_500Medium,
  Raleway_600SemiBold,
  Raleway_700Bold,
} from '@expo-google-fonts/raleway'
import { extendTheme } from 'native-base'

export const theme = {
  colors: {
    primary: {
      100: '#ff660050',
      200: '#c7c7c7',
      300: '#c3b482',
      400: '#000',
      500: '#000',
      600: '#266431',
    },
    bgContainer: '#fff',
  },
  fonts: {
    thin: Raleway_100Thin,
    extraLight: Raleway_200ExtraLight,
    light: Raleway_300Light,
    regular: Raleway_400Regular,
    medium: Raleway_500Medium,
    semibold: Raleway_600SemiBold,
    bold: Raleway_700Bold,
  },
}

export const NBtheme = extendTheme({
  colors: {
    primary: {
      100: '#ff660050',
      200: '#c7c7c7',
      300: '#c3b482',
      400: '#000',
      500: '#000',
      600: '#266431',
    },
    bgContainer: '#fff',
  },
})
